<template>
  <div class="login">
    <img class="header-img" :src="schoolInfo ? schoolInfo.loginImageUrl : ''" />
    <span class="enter-box" @click="toApply">
        <span class="iconfont icon-login_choose"></span>
        <span class="enter-box-title">班型价格</span>
    </span>
    <div class="box">
      <img class="title-img" src="../../assets/home/login_header.png" alt="" />
      <span class="input-box">
        <!-- <img class="img" src="../../assets/other/login_name.png" /> -->
        <span class="iconfont icon-login_name"></span>
        <span class="line"></span>
        <van-field
          class="input-login"
          v-model="userInfo.name"
          placeholder="请输入姓名"
        />
      </span>
      <span class="input-box">
        <!-- <img class="img" src="../../assets/other/login_mobile.png" /> -->
        <span class="iconfont icon-login_mobile"></span>
        <span class="line"></span>
        <van-field
          class="input-login"
          v-model="userInfo.mobile"
          placeholder="请输入手机号"
        />
      </span>
      <span class="input-box">
        <!-- <img class="img" src="../../assets/other/login_id.png" /> -->
        <span class="iconfont icon-login_id"></span>
        <span class="line"></span>
        <van-field
          class="input-login"
          v-model="userInfo.idNo"
          placeholder="请输入身份证号"
        />
      </span>
      <span class="login-btn" @click="loginClick">确定</span>
    </div>
    <div class="from-text">{{ staffInfo }}</div>
  </div>
</template>

<script>
import { Dialog, Field } from "vant";
import { Login_Api, GetSchoolInfo_Api, Logout_Api } from "@/api/login";
import { setLocalStorage, removeLocalStorage } from "core/utils/local-storage";
// import { stringify } from "qs";

export default {
  components: {
    [Field.name]: Field,
    [Dialog.name]: Dialog,
  },

  data() {
    return {
      userInfo: {
        name: "",
        idNo: "",
        mobile: "",
      },
      staffInfo: "",
      schoolInfo: null,
    };
  },

  created() {
    // let memberInfo = getLocalStorage("memberInfo");
    let memberInfo = null;
    if (!memberInfo) {
      this.$reqPost(Logout_Api, {}).then(() => {});
    }
    this.getSchoolInfo();
  },
  methods: {
    toApply() {
      this.$router.replace({ name: "apply" });
    },
    loginClick() {
      var params = {
        ...this.userInfo,
      };

      let that = this;

      this.$reqPost(Login_Api, params).then((res) => {
        if (res.code == 200) {
          if (res.map.code) {
            this.$router.push({ name: "register", params: { params: params } });
          } else if (res.map.alert) {
            Dialog.confirm({
              title: "提示",
              message: res.map.message,
            }).then(() => {
              that.forceLogin(params);
            });
          } else {
            setLocalStorage({
              memberInfo: res.map.member,
            });
            if (this.$common.classId) {
              this.$router.replace({
                name: "submitOrder",
                query: { itemId: this.$common.classId },
              });
              this.$common.classId = 0;
            } else {
              this.$router.replace({ name: "home" });
            }
          }
        } else {
          that.$reqPost(Logout_Api, {}).then(() => {
            removeLocalStorage(["memberInfo"]);
          });
        }
      });
    },

    forceLogin(params) {
      params.force = true;
      this.$reqPost(Login_Api, params).then((res) => {
        if (res.code == 200) {
          setLocalStorage({
            memberInfo: res.map.member,
          });
          // this.$router.replace({ name: "home" });
          if (this.$common.classId) {
            this.$router.replace({
              name: "submitOrder",
              query: { itemId: this.$common.classId },
            });
            this.$common.classId = 0;
          } else {
            this.$router.replace({ name: "home" });
          }
        }
      });
    },

    getSchoolInfo() {
      this.$reqGet(GetSchoolInfo_Api, {}).then((res) => {
        this.schoolInfo = res.map.object;
        this.userInfo.schoolName = res.map.object.name;
        this.staffInfo = res.map.staffInfo;

        setLocalStorage({
          themeColor: res.map.object.themeColor2,
        });
        this.$store.commit("changeThemeType", res.map.object.themeColor2);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 343px;
  height: 355px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.18);
  border-radius: 8px;
  margin: 198px auto 0 auto;
  z-index: 99;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title-img {
    width: 156px;
    height: 25px;
    margin: 23px 0;
  }

  .input-box {
    width: 283px;
    height: 40px;
    border-radius: 29px;
    border: 1px solid var(--theme-color);
    margin-bottom: 28px;
    display: flex;
    align-items: center;
    .iconfont {
      font-size: 18px;
      margin: 0 12px;
    }
  }
  .img {
    width: 20px;
    height: 20px;
    margin: 0 12px;
  }
  .line {
    width: 1px;
    height: 16px;
    background-color: var(--theme-color);
  }
  .input-login {
    margin-left: 12px;
    background-color: transparent;
    width: 218px;
    height: 26px;
    max-height: 26px;
  }

  .login-btn {
    width: 283px;
    height: 40px;
    background: var(--theme-color);
    border-radius: 29px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

.login {
  width: 100%;

  .van-cell {
    width: 218px;
    padding: 0;
    background-color: transparent;
  }
  .from-text {
    color: #333333;
    font-size: 16px;
    width: 100%;
    text-align: center;
    margin: 40px 0;
  }
  .header-img {
    width: 100%;
    // min-height: 368px;
    z-index: -1;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .enter-box {
    display: flex;
    position: absolute;
    top: 64px;
    right: 16px;
    width: 144px;
    height: 40px;
    align-items: center;
    background-color: white;
    border-radius: 20px;

    .iconfont{
      font-size: 20px;
      margin-left: 22px;
      color: var(--theme-color);
    }
    .enter-box-title{
      font-size: 16px;
      font-weight: 500;
      margin-left: 14px;
      color: var(--theme-color);
    }
  }
}
</style>
